import React, { FC, useEffect, useState } from 'react';
import { c, countUp } from '../../../../lib/util';
import CounterBlock from '../../../CounterBlock';
import Logo from '../../../Logo';
import ShadowBackground from '../../../ShadowBackground';
import Slide, { SlidePosition, SlideProps } from '../../Slide';
import styles from './styles.module.scss';

const Financial: FC<SlideProps> = ({ position }): JSX.Element => {
    const [ delayedPosition, setDelayedPosition ] = useState<SlidePosition>();

    useEffect(() => {
        setTimeout(() => setDelayedPosition(position), position === 'center' ? 500 : 1);
    }, [position]);

    useEffect(() => {
        countUp(document.querySelector('#count-x > div > span') as HTMLSpanElement, 181500, 1500, true)();
        countUp(document.querySelector('#count-y > div > span') as HTMLSpanElement, 500000, 2500, true)();
        countUp(document.querySelector('#count-z > div > span') as HTMLSpanElement, 2930427, 2500, false)();
    }, [delayedPosition]);

    return (
        <Slide position={position}>
            <div className={c([styles.financial, delayedPosition === 'center' && styles.isActive])}>
                <ShadowBackground random={11} bottom>
                    <Slide.Body>
                        <Logo
                            className={styles.logo}
                        />
                        <Slide.Title>Financieel</Slide.Title>
                        
                        <div className="row align-items-center">
                            <div className="col-4">
                                <div className={styles.fase}>
                                    <div className={styles.title}>
                                        Fase 1
                                    </div>
                                    <div
                                        className={c([styles.block, styles.b1])}
                                        id="count-x"
                                    >
                                        <CounterBlock
                                            number={181500}
                                            title="Ontwikkeling"
                                            content="Iris & Aris"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className={styles.fase} style={{ border: 0 }}>
                                    <div className={styles.title}>
                                        Fase 2
                                    </div>
                                    <div
                                        className={c([styles.block, styles.b2])}
                                        id="count-y"
                                    >
                                        <CounterBlock
                                            number={500000}
                                            title="Extra investeringsronde"
                                            content="(Pilot) klanten of Angel Investor (mede-eignaar)"
                                        />
                                    </div>
                                    
                                    <div className={styles.footer}>
                                        1 december 2022
                                        <small>&nbsp;</small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className={c([styles.fase, styles.last])}>
                                    <div className={styles.title}>
                                        Fase 3
                                    </div>

                                    <div
                                        className={c([styles.block, styles.b2])}
                                        id="count-z"
                                    >
                                        <CounterBlock
                                            number={500000}
                                            title="Omzet (1% van de markt)"
                                            content="12 maanden"
                                        />
                                    </div>
                                    
                                    <div className={styles.footer}>
                                        1 maart 2023
                                        <small>Lancering</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slide.Body>
                </ShadowBackground>
            </div>
        </Slide>
    );
}

export default Financial;
